<template>
  <div>
    <employee-leaves-status-card
      v-if="employeeData"
      :leave-details="employeeData.leave_details"
      @goBack="
        $router.push({
          name: 'apps-employees-view',
          params: { id: $router.currentRoute.params.id },
        })
      "
    />

    <!-- handle leave by adding or updating its infos -->
    <b-row v-if="!toggleLeavesDetailsEditForm && !toggleAddLeaveForm">
      <b-col lg="2" cols="12">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="toggleAddLeaveForm = true"
        >
          <feather-icon icon="CalendarIcon" class="mr-50" />
          <span class="align-middle">Gérer</span>
        </b-button>
      </b-col>
      <b-col lg="2" cols="12">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="warning"
          @click="toggleLeavesDetailsEditForm = true"
        >
          <feather-icon icon="Edit3Icon" class="mr-50" />
          <span class="align-middle">Mettre à jour</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Add a leave -->
    <b-row
      v-if="employeeData && toggleAddLeaveForm && !toggleLeavesDetailsEditForm"
      class="match-height"
    >
      <b-col cols="12">
        <b-card no-body>
          <b-card-header class="mb-2">
            <b-card-title>Enregistrer un congé</b-card-title>
            <b-card-text class="mr-25 mb-0">
              <i
                ><span class="text-danger">*</span>Lors de l'enregistrement d'un
                congé, veuillez juste renseigner la date de début et le nombre
                de jours ouvrables demandés.</i
              >
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <employee-leaves-add-form
              :leave-details="employeeData.leave_details"
              :job-start-date="employeeData.contract_start_date"
              @leaveSaved="toggleAddLeaveForm = false"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- Update leaves details -->
    <b-row
      v-if="employeeData && toggleLeavesDetailsEditForm && !toggleAddLeaveForm"
      class="match-height"
    >
      <b-col cols="12">
        <b-card no-body>
          <b-card-header class="mb-2">
            <b-card-title>Mise à jour des détails de congés</b-card-title>
            <b-card-text class="mr-25 mb-0">
              <i
                ><span class="text-danger">*</span>Les informations affichées
                ici, une fois modifiées pourraient impacter le congé de
                l'employé(e)</i
              >
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <employee-leaves-edit-details
              :leave-details="employeeData.leave_details"
              @leavesDetailsUpdated="toggleLeavesDetailsEditForm = false"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!--/ Update leaves details -->
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import EmployeeLeavesStatusCard from "./EmployeeLeavesStatusCard.vue";
import EmployeeLeavesEditDetails from "./EmployeeLeavesEditDetails.vue";
import EmployeeLeavesAddForm from "./EmployeeLeavesAddForm.vue";
import { ref, watch } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import useEmployeesList from "../employees-list/useEmployeesList";

export default {
  name: "EmployeeLeaves",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BButton,
    EmployeeLeavesStatusCard,
    EmployeeLeavesEditDetails,
    EmployeeLeavesAddForm,
  },
  directives: {
    Ripple,
  },
  setup() {
    const employeeData = ref(null);
    const toggleLeavesDetailsEditForm = ref(false);
    const toggleAddLeaveForm = ref(false);

    const {
      // data
      getLeavesHistories,
      fetchEmployeeProperties,
    } = useEmployeesList();

    const getLeavesDetails = () => {
      store
        .dispatch("employee/fetchEmployee", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          employeeData.value = response;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            employeeData.value = undefined;
          }
        });
    };

    getLeavesDetails();

    watch([toggleLeavesDetailsEditForm, toggleAddLeaveForm], () => {
      getLeavesDetails();
    });

    return {
      employeeData,
      toggleAddLeaveForm,
      toggleLeavesDetailsEditForm,
    };
  },
};
</script>
