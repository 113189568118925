<template>
  <validation-observer
    #default="{ handleSubmit, invalid }"
    ref="refFormObserver"
  >
    <b-form
      @submit.stop.prevent="handleSubmit(onSubmit)"
      @reset.prevent="closeAddLeaveForm"
    >
      <b-row>
        <b-col cols="6">
          <b-row class="mb-2">
            <!-- From -->
            <b-col md="6" cols="12">
              <validation-provider
                #default="validationContext"
                name="start_date"
                rules="required"
              >
                <b-form-group
                  label="Date de début :"
                  label-for="from"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="from"
                    v-model="leaveData.from"
                    :config="flatConfig"
                    class="form-control"
                    placeholder="Date de début..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- number of days -->
            <b-col md="6" cols="12">
              <validation-provider
                #default="validationContext"
                name="day_number"
                rules="required"
              >
                <b-form-group
                  label="Nombre de jour(s) :"
                  label-for="day_number"
                  :state="getValidationState(validationContext)"
                >
                  <b-input-group append="Jour(s)">
                    <b-form-input
                      id="day_number"
                      v-model="dayNumber"
                      type="number"
                      placeholder="Nombre de jour(s)..."
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <!-- To -->
            <b-col md="6" cols="12">
              <validation-provider
                #default="validationContext"
                name="end_date"
                rules="required"
              >
                <b-form-group
                  label="Date de fin :"
                  label-for="to"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="from"
                    v-model="leaveData.to"
                    :config="flatConfig"
                    class="form-control"
                    placeholder="Date de fin..."
                    disabled
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="border-left" cols="6">
          <b-row>
            <!-- motive -->
            <b-col lg="8" cols="12">
              <validation-provider
                #default="validationContext"
                name="motive"
                rules=""
              >
                <b-form-group label="Motif :" label-for="motive">
                  <b-form-textarea
                    id="motive"
                    v-model="leaveData.motive"
                    :state="getValidationState(validationContext)"
                    placeholder="Motif..."
                    rows="1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="invalid || isLoading"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
          >
            Annuler
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";

import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import store from "@/store";
import router from "@/router";
import { computed, ref, watch } from "@vue/composition-api";

import moment from "moment";
moment.locale("fr");

export default {
  name: "EmployeeSalaryStatementEditDetails",
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BRow,
    BCol,
    BForm,
    BButton,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    leaveDetails: {
      type: Object,
      required: true,
    },
    jobStartDate: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const toast = useToast();
    const flatConfig = ref({
      wrap: true,
      altFormat: "d-m-Y",
      dateFormat: "d-m-Y",
      // defaultDate: new Date(),
      minDate: lastLeaveDate.value,
      locale: French, // locale for this instance only
    });

    const blankLeaveData = {
      from: null,
      to: null,
      motive: "",
    };
    const dayNumber = ref(0);

    const leaveData = ref(JSON.parse(JSON.stringify(blankLeaveData)));

    const startDate = computed(() => {
      return leaveData.value.from;
    });

    watch([startDate, dayNumber], () => {
      if (startDate.value != null && dayNumber.value > 0) {
        const endDate = addBusinessDays(startDate.value, dayNumber.value);

        // set the end date of leave
        leaveData.value.to = endDate.format("DD-MM-YYYY");
      } else {
        leaveData.value.to = null;
      }
    });

    const lastLeaveDate = computed(() => {
      let fromDate = props.leaveDetails.last_leave_from_date;
      return fromDate ? fromDate : props.jobStartDate
    })

    const addBusinessDays = (startDate, days) => {
      let currDate = moment(startDate, "DD-MM-YYYY"); // clone start date
      let leaveDaysCount = 1;

      while (leaveDaysCount < days) {
        currDate.add(1, "days");

        // check if it's not a saturday (6) or sunday (0)
        if (currDate.day() !== 0 && currDate.day() !== 6) leaveDaysCount++;
      }

      return currDate;
    };

    const resetDetails = () =>
      (leaveData.value = JSON.parse(JSON.stringify(blankLeaveData)));

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetDetails);

    const closeAddLeaveForm = () => {
      emit("leaveSaved");
      resetForm();
    };

    const onSubmit = () => {
      leaveData.value.day_number = dayNumber.value // add day number to leave data
      const payload = {
        uid: router.currentRoute.params.id,
        leave: leaveData.value,
      };

      store
        .dispatch("employee/addLeave", payload)
        .then(({success, data, errors}) => {
          if (success) {
            closeAddLeaveForm();
            toast({
              component: ToastificationContent,
              props: {
                title: "Le congé a été avec succès.",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            refFormObserver.value.setErrors({ ...errors });
            toast({
              component: ToastificationContent,
              props: {
                title: "Problème d'enregistrement de congé.",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title:
                "Une erreur est survenue lors de l'enregistrement de congé.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    return {
      leaveData,
      dayNumber,
      onSubmit,
      resetDetails,
      refFormObserver,
      getValidationState,
      closeAddLeaveForm,
      flatConfig,

      isLoading,
    };
  },
};
</script>
