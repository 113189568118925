<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>
        <b-button
          variant="primary"
          class="btn-icon"
          size="sm"
          @click="$emit('goBack')"
        >
          <feather-icon icon="ArrowLeftIcon" />
        </b-button>
        Informations
      </b-card-title>
      <b-card-text class="mr-25 mb-0">
        <i class="mr-1">Dernière mise à jour le</i
        >{{ formatDate(leaveDetails.updated_at) }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in statisticsItems"
          :key="index"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="38" :variant="item.color">
                <feather-icon size="14" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BButton,
} from "bootstrap-vue";
import { formatDate } from "@core/utils/filter";

export default {
  name: "EmployeeSalaryStatementStatusCard",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
  },
  props: {
    leaveDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statisticsItems() {
      return [
        {
          icon: "UserPlusIcon",
          color: "light-primary",
          title: this.leaveDetails.total_remain,
          subtitle: "Total Restant",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserCheckIcon",
          color: "light-info",
          title: this.leaveDetails.year_remain,
          subtitle: "Total Annuel",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserMinusIcon",
          color: "light-danger",
          title: this.leaveDetails.total_consumed,
          subtitle: "Total de Jour(s) Consommé(s)",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "TagIcon",
          color: "light-success",
          title: this.leaveDetails.leaves_count,
          subtitle: "Nombre de congés",
          customClass: "",
        },
      ];
    },
  },
  methods: {
    formatDate,
  },
};
</script>
